import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

import { GlobalService } from "src/app/services/global.service";
import { MapPlaceL } from "src/app/models/mapplace";
import { PRoute } from "src/app/models/p-route";
import { localStoreSavedPlaces, localStoreSavedRoutes } from "../maps-utils/utils-maps-local-storage";
import { SavedPlaceCategory, SavedRouteCategory, SavedTourCategory } from "src/app/models/saved-item-categories";
import { LineStyle } from "src/app/models/line-style";
import { EnumGlobalStatusCode, MapTour, MapTourCategory, User } from "src/dto.generated/api";
import { PointStyle } from "src/app/models/point-style";
import { convertLRouteCatToSRouteCat, createNewRouteCategory } from "../maps-utils/utils-route-categories";
import { convertLRouteToSRoute } from "../maps-utils/utils-route";
import { convertLPlaceCatToSPlaceCat } from "../maps-utils/utils-place-categories";
import { convertLPlaceToSPlace } from "../maps-utils/utils-place";
import { ApiError } from "src/app/models/api-error";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { TourData } from "src/app/models/tour-data";
import { convertMapTourToTourData } from "../maps-utils/utils-tour";
import { convertLTourCatToSTourCat, convertSTourCatToLTourCat, createDefaultSavedTourCategoryRemember, createNewTourCategory } from "../maps-utils/utils-tour-categories";


@Component({
  selector: "app-saved-items-component",
  templateUrl: "./saved-items.component.html",
  styleUrls: ["./saved-items.component.scss"]
})
export class SavedItemsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() loggedInUser: User;
  @Input() languageCode: string;
  @Input() uiType: string;
  @Input() isOnline: boolean;
  @Input() saveType: string;
  @Input() savedRoutesLocaly: SavedRouteCategory[];
  @Input() savedRoutesServer: SavedRouteCategory[];
  @Input() savedPlacesLocaly: SavedPlaceCategory[];
  @Input() savedPlacesServer: SavedPlaceCategory[];

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() savedPlacesChange: EventEmitter<MapPlaceL[]> = new EventEmitter();
  @Output() redrawSavedPlacesLocaly: EventEmitter<void> = new EventEmitter();
  @Output() redrawSavedPlacesServer: EventEmitter<void> = new EventEmitter();

  @Output() savedRoutesChange: EventEmitter<MapPlaceL[]> = new EventEmitter();
  @Output() redrawSavedRoutesLocaly: EventEmitter<void> = new EventEmitter();
  @Output() redrawSavedRoutesServer: EventEmitter<void> = new EventEmitter();
  @Output() activateSavedRouteLocaly: EventEmitter<PRoute> = new EventEmitter();
  @Output() activateSavedRouteServer: EventEmitter<PRoute> = new EventEmitter();
  @Output() showRouteDetailsLocaly: EventEmitter<string> = new EventEmitter();
  @Output() showRouteDetailsServer: EventEmitter<string> = new EventEmitter();
  @Output() activateSavedTourServer: EventEmitter<MapTour> = new EventEmitter();
  @Output() saveTypeChange: EventEmitter<string> = new EventEmitter();
  @Output() zoomToRoute: EventEmitter<PRoute> = new EventEmitter();
  @Output() zoomToPlace: EventEmitter<MapPlaceL> = new EventEmitter();
  @Output() showError: EventEmitter<ApiError> = new EventEmitter();

  // fileUrl
  public fileUrlPlaces: SafeValue;
  public fileUrlRoutes: SafeValue;

  // general variables
  public isTestUser: boolean;
  public newCategoryName: string;
  public activeTabIndex: number;

  // place-variables
  public savedPlaces: SavedPlaceCategory[];
  public selectedSavedPlaceCategoryIndex: number;
  public isActiveRenameSavedPlaceCategory: boolean;
  public selectedPlaceIndex: number;
  public isActiveRenameSavedPlace: boolean;
  public orderPlaceCategories: boolean;

  // route-variables
  public savedRoutes: SavedRouteCategory[];
  public selectedSavedRouteCategoryIndex: number;
  public isActiveRenameSavedRouteCategory: boolean;
  public selectedRouteIndex: number;
  public isActiveRenameSavedRoute: boolean;
  public orderRouteCategories: boolean;

  // tour-variables
  public savedToursServer: SavedTourCategory[];
  public savedTours: SavedTourCategory[];
  public selectedSavedTourCategoryIndex: number;
  public isActiveRenameSavedTourCategory: boolean;
  public selectedTourIndex: number;
  public isActiveRenameSavedTour: boolean;
  public orderTourCategories: boolean;


  public tourData: TourData;

  // color select
  public isActiveColorSelect = true;


  @ViewChild("catplaceinput") catPlaceInputElement: ElementRef;
  @ViewChild("placeinput") placeInputElement: ElementRef;
  @ViewChild("catrouteinput") catRouteInputElement: ElementRef;
  @ViewChild("routeinput") routeInputElement: ElementRef;
  @ViewChild("cattourinput") catTourInputElement: ElementRef;
  @ViewChild("tourinput") tourInputElement: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private globalService: GlobalService,
  ) { }

  public async ngOnInit() {
    // console.log("saved-items:ngOnInit-isOnline", this.isOnline);
    if (!this.saveType) {
      this.saveType = "server";
    }
    if (!this.loggedInUser || !this.isOnline) { this.saveType = "local"; }
    this.savedPlaces = this.savedPlacesLocaly;
    if (this.saveType === "server") { this.savedPlaces = this.savedPlacesServer; }
    // console.log("saved-items:ngOnInit-savedPlaces", this.savedPlaces);
    for (const cat of this.savedPlaces) {
      if (cat.type === "$remember") { cat.open = true; }
    }
    this.savedRoutes = this.savedRoutesLocaly;
    if (this.saveType === "server") { this.savedRoutes = this.savedRoutesServer; }
    // console.log("saved-items:ngOnInit-savedRoutes", this.savedRoutes);
    for (const cat of this.savedRoutes) {
      if (cat.type === "$remember") { cat.open = true; }
    }
    this.savedTours = new Array<SavedTourCategory>();


    if (this.loggedInUser && this.loggedInUser.id === 1) { this.isTestUser = true; }
  }
  public ngAfterViewInit() {
    // console.log("saved-items:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onBackClick() {
    this.back.emit();
  }

  // matTabChanged
  public async onTabChanged(index: number) {
    // console.log("saved-items:onTabChanged-index", index);
    this.activeTabIndex = index;
    if (this.activeTabIndex === 2) {
      await this.loadTourCategories();
      this.loadTours();
    }
  }


  // tours
  private async loadTourCategories() {
    const result = await this.globalService.getTourCategories();
    // console.log("saved-items:loadTourCategories-result", result);
    if (result.status !== EnumGlobalStatusCode.Success) {
      // §todo error handling
    }
    const tourCategories = result.tourCategories;
    this.savedToursServer = new Array<SavedTourCategory>();
    for (const sCat of tourCategories) {
      const lCat = convertSTourCatToLTourCat(sCat);
      if (lCat.type === "$remember") { lCat.open = true; }
      this.savedToursServer.push(lCat);
    }
    if (this.savedToursServer.length === 0) {
      const defaultLCat = createDefaultSavedTourCategoryRemember(this.languageCode);
      const sCat = convertLTourCatToSTourCat(defaultLCat);
      const result = await this.globalService.addTourCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        // §todo error handling
      }
      const addedTourCat = result.addedTourCategory;
      defaultLCat.categoryId = addedTourCat.id;
      this.savedToursServer.push(defaultLCat);

    }
    this.savedTours = this.savedToursServer;
    // console.log("saved-items:loadTourCategories-savedTours", this.savedTours);
  }
  private async loadTours() {
    const result = await this.globalService.getTours();
    if (result.status !== EnumGlobalStatusCode.Success) {
      // §todo error handling
    }
    const tours = result.tours;
    for (const tour of tours) {
      // console.log("saved-items:loadTours-tour", tour);
      this.addTourToTourCategory(tour);
    }
  }
  private async addTourToTourCategory(tour: MapTour) {
    for (const cat of this.savedToursServer) {
      if (cat.categoryId === tour.categoryId) {
        cat.tourItems.push(tour);
        return;
      }
    }
    // if category not found add tour to type $remember
    for (const cat of this.savedToursServer) {
      if (cat.type === "$remember") {
        tour.categoryId = cat.categoryId;
        cat.tourItems.push(tour);
        return;
      }
    }
  }

  // public onTourClick(index: number) {
  //   this.selectedTourIndex = index;
  //   const tour = this.savedToursServer[index];
  //   console.log("saved-items:onTourClick-tour", tour);

  //   this.tourData = convertMapTourToTourData(tour);
  // }

  // save-type-routes
  public onSaveTypeRoutesClick(type: string) {
    // console.log("saved-items:onSaveTypeRoutesClick-type", type);
    if (type === "local") {
      this.savedRoutes = this.savedRoutesLocaly;
      this.savedPlaces = this.savedPlacesLocaly;
    }
    if (type === "server") {
      this.savedRoutes = this.savedRoutesServer;
      this.savedPlaces = this.savedPlacesServer;
    }
    this.saveType = type;
    this.saveTypeChange.emit(type);
  }


  // saved-routes-categories
  public onOrderRouteCategoriesClick() {
    this.orderRouteCategories = !this.orderRouteCategories;
  }

  // route-categories
  public async onRouteCategoryVisiblilityChange(catIndex: number) {
    // console.log("saved-items:onLocalRouteCatVisiblilityChange");
    if (this.saveType === "local") {
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const lCat = this.savedRoutes[catIndex];
      const sCat = convertLRouteCatToSRouteCat(lCat);
      sCat.userId = this.loggedInUser.id;
      const result = await this.globalService.updateRouteCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateRouteCategory");
        this.showError.emit(apiErr);
        return;
      }
    }
    this.redrawSavedRoutes();
  }
  public async onDeleteRouteCategoryClick(catIndex: number) {
    if (this.saveType === "local") {
      this.savedRoutes.splice(catIndex, 1);
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const cat = this.savedRoutes[catIndex];
      const result = await this.globalService.deleteRouteCategory(cat.categoryId);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "deleteRouteCategory");
        this.showError.emit(apiErr);
        return;
      }

      this.savedRoutes.splice(catIndex, 1);
    }
    this.redrawSavedRoutes();
  }
  public onRenameRouteCategoryClick(index: number) {
    this.selectedSavedRouteCategoryIndex = index;
    this.isActiveRenameSavedRouteCategory = true;
    setTimeout(() => { this.catRouteInputElement.nativeElement.focus(); });
  }
  public async onRouteCategoryNameKeyup(event: KeyboardEvent, catIndex: number) {
    if (event.key === "Enter") {
      if (this.saveType === "local") {
        localStoreSavedRoutes(this.savedRoutes);
      }
      if (this.saveType === "server") {
        const lCat = this.savedRoutes[catIndex];
        const sCat = convertLRouteCatToSRouteCat(lCat);
        sCat.userId = this.loggedInUser.id;
        const result = await this.globalService.updateRouteCategory(sCat);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "updateRouteCategory");
          this.showError.emit(apiErr);
          return;
        }
      }
      this.isActiveRenameSavedRouteCategory = false;
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }

  public async onRouteCategoryNameBlur(catIndex: number) {
    // console.log("saved-items:onRouteCategoryNameBlur");
    if (this.saveType === "local") {
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const lCat = this.savedRoutes[catIndex];
      const sCat = convertLRouteCatToSRouteCat(lCat);
      sCat.userId = this.loggedInUser.id;
      const result = await this.globalService.updateRouteCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateRouteCategory");
        this.showError.emit(apiErr);
        return;
      }
    }
    this.isActiveRenameSavedRouteCategory = false;
  }
  public async onNewRouteCategoryNameKeyup(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.newCategoryName || this.newCategoryName === "") { return; }
      await this.onCreateNewRouteCategory();
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onCreateNewRouteCategory() {
    // console.log("saved-items:onCreateNewRouteCategory");
    const lCat = createNewRouteCategory(this.newCategoryName);
    if (this.saveType === "local") {
      this.savedRoutes.push(lCat);
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const sCat = convertLRouteCatToSRouteCat(lCat);
      sCat.userId = this.loggedInUser.id;
      const result = await this.globalService.addRouteCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "addRouteCategory");
        this.showError.emit(apiErr);
        return;
      }
      const addedCategory = result.addedRouteCategory;
      lCat.categoryId = addedCategory.id;
      this.savedRoutes.push(lCat);
    }
    this.newCategoryName = "";
  }
  public async onRouteCategoryColorChange(catIndex: number) {
    // console.log("saved-items:onRouteCategoryColorChange-colorValue", this.colorValue);
    // console.log("saved-items:onRouteCategoryColorChange-catIndex", catIndex);
    if (this.saveType === "local") {
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const lCat = this.savedRoutes[catIndex];
      const sCat = convertLRouteCatToSRouteCat(lCat);
      sCat.userId = this.loggedInUser.id;
      const result = await this.globalService.updateRouteCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateRouteCategory");
        this.showError.emit(apiErr);
        return;
      }
    }
    this.redrawSavedRoutes();
  }

  public async onDropRouteCategory(event: CdkDragDrop<string[]>) {
    // console.log("saved-items:onDropRoute-event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    // console.log("saved-items:onDropRoute-savedRoutes", this.savedRoutes);
    if (this.saveType === "local") {
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      for (const lCat of this.savedRoutesServer) {
        const index = this.savedRoutesServer.indexOf(lCat);
        if (index !== lCat.order) {
          lCat.order = index;
          const sCat = convertLRouteCatToSRouteCat(lCat);
          const result = await this.globalService.updateRouteCategory(sCat);
          if (result.status !== EnumGlobalStatusCode.Success) {
            const apiErr = this.createApiError(result, "updateRouteCategory");
            this.showError.emit(apiErr);
            return;
          }
        }
      }
    }
  }

  // saved routes
  public onRenameRouteClick(index: number, catIndex: number) {
    this.selectedRouteIndex = index;
    this.selectedSavedRouteCategoryIndex = catIndex;
    this.isActiveRenameSavedRoute = true;
    setTimeout(() => { this.routeInputElement.nativeElement.focus(); });
  }
  public async onRouteNameKeyup(event: KeyboardEvent, catIndex: number, index: number) {
    if (event.key === "Enter") {
      this.isActiveRenameSavedRoute = false;
      if (this.saveType === "local") {
        localStoreSavedRoutes(this.savedRoutes);
      }
      if (this.saveType === "server") {
        const cat = this.savedRoutes[catIndex];
        const lRoute = cat.routeItems[index];
        const sRoute = convertLRouteToSRoute(lRoute, cat.categoryId);
        const result = await this.globalService.updateRoute(sRoute);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "updateRoute");
          this.showError.emit(apiErr);
          return;
        }
      }
      if (this.savedRoutes[catIndex].visible) { this.redrawSavedRoutes(); }
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onRouteNameBlur(catIndex: number, index: number) {
    // console.log("saved-items:onRouteNameBlur-catIndex", this.selectedSavedPlaceCategoryIndex);
    this.isActiveRenameSavedRoute = false;
    if (this.saveType === "local") {
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const cat = this.savedRoutes[catIndex];
      const lRoute = cat.routeItems[index];
      const sRoute = convertLRouteToSRoute(lRoute, cat.categoryId);
      const result = await this.globalService.updateRoute(sRoute);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateRoute");
        this.showError.emit(apiErr);
        return;
      }
    }
    if (this.savedRoutes[catIndex].visible) { this.redrawSavedRoutes(); }
  }
  public async onDeleteRouteClick(index: number, catIndex: number) {

    if (this.saveType === "local") {
      this.savedRoutes[catIndex].routeItems.splice(index, 1);
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const routeId = this.savedRoutes[catIndex].routeItems[index].id;
      const result = await this.globalService.deleteRoute(routeId);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "deleteRoute");
        this.showError.emit(apiErr);
        return;
      }
      this.savedRoutes[catIndex].routeItems.splice(index, 1);
    }
    this.redrawSavedRoutes();
  }
  public onZoomToRouteClick(index: number, catIndex: number) {
    const cat = this.savedRoutes[catIndex];
    const lRoute = cat.routeItems[index];
    this.zoomToRoute.emit(lRoute);
    if (!cat.visible) {
      cat.visible = true;
      this.onRouteCategoryVisiblilityChange(catIndex);
    }
  }
  public async onDropRoute(event: CdkDragDrop<string[]>) {
    // console.log("saved-items:onDropRoute-event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const catIndex = event.container.id;
      this.savedRoutes[catIndex].open = true;
    }
    if (this.saveType === "local") {
      localStoreSavedRoutes(this.savedRoutes);
    }
    if (this.saveType === "server") {
      const catIndex = event.container.id;
      // console.log("saved-items:onDropRoute-catIndex", catIndex);
      const lCat = this.savedRoutesServer[catIndex];
      const droppedLRoute = lCat.routeItems[event.currentIndex];
      droppedLRoute.categoryId = lCat.id;
      const droppedSRoute = convertLRouteToSRoute(droppedLRoute, lCat.categoryId);
      const result = await this.globalService.updateRoute(droppedSRoute);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateRoute");
        this.showError.emit(apiErr);
        return;
      }
      for (const lRoute of lCat.routeItems) {
        const index = lCat.routeItems.indexOf(lRoute);
        if (index !== lRoute.order) {
          lRoute.order = index;
          const sRoute = convertLRouteToSRoute(lRoute, lCat.categoryId);
          // console.log("saved-items:onDropRoute-sRoute", sRoute);
          const result = await this.globalService.updateRoute(sRoute);
          if (result.status !== EnumGlobalStatusCode.Success) {
            const apiErr = this.createApiError(result, "updateRoute");
            this.showError.emit(apiErr);
            return;
          }
        }
      }
    }
    this.redrawSavedRoutes();
  }
  public onActivateRouteClicked(itemIndex: number, catIndex: number) {
    // console.log("saved-items:onActivateRouteClicked-catIndex", catIndex);
    // console.log("saved-items:onActivateRouteClicked-itemIndex", itemIndex);
    if (this.saveType === "local") {
      this.activateSavedRouteLocaly.emit(this.savedRoutes[catIndex].routeItems[itemIndex]);
    }
    if (this.saveType === "server") {
      this.activateSavedRouteServer.emit(this.savedRoutes[catIndex].routeItems[itemIndex]);
    }
  }
  public onShowRouteDetailsClicked(catIndex: number, itemIndex: number) {
    // console.log("saved-items:onShowRouteDetailsClicked-index", itemIndex);
    const route = this.savedRoutes[catIndex].routeItems[itemIndex];
    if (this.saveType === "local") { this.showRouteDetailsLocaly.emit(route.routeId); }
    if (this.saveType === "server") { this.showRouteDetailsServer.emit(route.routeId); }
  }

  // export routes
  public onExportRoutesClick() {
    this.downloadRoutesAsJSON();
  }
  private async downloadRoutesAsJSON() {
    // console.log("saved-items:onDownloadRoutes");
    const data = JSON.stringify(this.savedRoutes);
    // console.log("saved-items:downloadRoutesAsJSON-data", data);
    const blob = new Blob([data], { type: "application/octet-stream" });
    this.fileUrlRoutes = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }
  // import routes
  public onFileChangeRoutes(event: any) {
    // console.log("saved-items:onFileChangeRoutes-event", event);
    const files = event.target.files;
    if (files.length === 0) { return; }
    const file = files[0];
    // console.log("saved-items:onFileChangeRoutes-file", file);

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      // console.log("FileReader-result", fileReader.result);
      const jsonString = fileReader.result as string;
      const obj = JSON.parse(jsonString);
      // console.log("saved-items:onFileChangePlaces-obj", obj);
      this.savedRoutes = obj;
      localStoreSavedRoutes(this.savedRoutes);
      this.redrawSavedRoutes();
    };
    fileReader.readAsText(file);
  }

  private redrawSavedRoutes() {
    if (this.saveType === "local") {
      this.redrawSavedRoutesLocaly.emit();
    }
    if (this.saveType === "server") {
      this.redrawSavedRoutesServer.emit();
    }
  }


  // save-type-places
  public onSaveTypePlacesClick(type: string) {
    // console.log("saved-items:onSaveTypePlacesClick-type", type);
    if (type === "local") {
      this.savedPlaces = this.savedPlacesLocaly;
      this.savedRoutes = this.savedRoutesLocaly;
    }
    if (type === "server") {
      this.savedPlaces = this.savedPlacesServer;
      this.savedRoutes = this.savedRoutesServer;
    }
    this.saveType = type;
    this.saveTypeChange.emit(type);
  }

  // saved-places-categories
  public async onOrderPlaceCategoriesClick() {
    this.orderPlaceCategories = !this.orderPlaceCategories;
  }
  public async onPlaceCategoryVisiblilityChange(catIndex: number) {
    // console.log("saved-items:onPlaceCategoryVisiblilityChange-catIndex", catIndex);
    if (this.saveType === "local") {
      localStoreSavedPlaces(this.savedPlacesLocaly);
    }
    if (this.saveType === "server") {
      const lCat = this.savedPlaces[catIndex];
      const sCat = convertLPlaceCatToSPlaceCat(lCat);
      const result = await this.globalService.updatePlaceCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updatePlaceCategory");
        this.showError.emit(apiErr);
        return;
      }
    }
    this.redrawSavedPlaces();
  }
  public async onDeletePlaceCategoryClick(catIndex: number) {
    if (this.saveType === "local") {
      this.savedPlaces.splice(catIndex, 1);
      localStoreSavedPlaces(this.savedPlaces);
    }
    if (this.saveType === "server") {
      const cat = this.savedPlaces[catIndex];
      const result = await this.globalService.deletePlaceCategory(cat.categoryId);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "deletePlaceCategory");
        this.showError.emit(apiErr);
        return;
      }
      this.savedPlaces.splice(catIndex, 1);
    }
    this.redrawSavedRoutes();
  }

  public onRenamePlaceCategoryClick(index: number) {
    this.selectedSavedPlaceCategoryIndex = index;
    this.isActiveRenameSavedPlaceCategory = true;
    setTimeout(() => { this.catPlaceInputElement.nativeElement.focus(); });
  }
  public async onPlaceCategoryNameKeyup(event: KeyboardEvent, catIndex: number) {
    if (event.key === "Enter") {
      if (this.saveType === "local") {
        localStoreSavedPlaces(this.savedPlaces);
      }
      if (this.saveType === "server") {
        const lCat = this.savedPlacesServer[catIndex];
        const sCat = convertLPlaceCatToSPlaceCat(lCat);
        const result = await this.globalService.updatePlaceCategory(sCat);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "updatePlaceCategory");
          this.showError.emit(apiErr);
          return;
        }
      }
      this.isActiveRenameSavedPlaceCategory = false;
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onPlaceCategoryNameBlur(catIndex: number) {
    if (this.saveType === "local") {
      localStoreSavedPlaces(this.savedPlacesLocaly);
    }
    if (this.saveType === "server") {
      const lCat = this.savedPlaces[catIndex];
      const sCat = convertLPlaceCatToSPlaceCat(lCat);
      const result = await this.globalService.updatePlaceCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updatePlaceCategory");
        this.showError.emit(apiErr);
        return;
      }

    }
    this.isActiveRenameSavedPlaceCategory = false;
  }
  public async onNewPlaceCategoryNameKeyup(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.newCategoryName || this.newCategoryName === "") { return; }
      await this.onCreateNewPlaceCategory();
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onCreateNewPlaceCategory() {
    // console.log("saved-items:onCreateNewPlaceCategory");
    const newCategory = this.createNewPlaceCategory(this.newCategoryName);
    if (this.saveType === "local") {
      this.savedPlaces.push(newCategory);
      localStoreSavedPlaces(this.savedPlaces);
    }
    if (this.saveType === "server") {
      const sCat = convertLPlaceCatToSPlaceCat(newCategory);
      sCat.userId = this.loggedInUser.id;
      const result = await this.globalService.addPlaceCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "addPlaceCategory");
        this.showError.emit(apiErr);
        return;
      }
      const addedCategory = result.addedPlaceCategory;
      newCategory.categoryId = addedCategory.id;
      this.savedPlaces.push(newCategory);
    }
    this.newCategoryName = "";
  }
  private createNewPlaceCategory(categoryName: string) {
    const newCategory = {} as SavedPlaceCategory;
    newCategory.name = this.newCategoryName;
    newCategory.type = "$user";
    newCategory.placeItems = new Array<MapPlaceL>();
    newCategory.pointStyle = {} as PointStyle;
    newCategory.pointStyle.strokeColor = "#888888";
    return newCategory;
  }

  public async onPlaceCategoryColorChange(catIndex: number) {
    // console.log("saved-items:onPlaceCategoryColorChange-colorValue", this.colorValue);
    // console.log("saved-items:onPlaceCategoryColorChange-catIndex", catIndex);
    if (this.saveType === "local") {
      localStoreSavedPlaces(this.savedPlaces);
    }
    if (this.saveType === "server") {
      const lCat = this.savedPlaces[catIndex];
      const sCat = convertLPlaceCatToSPlaceCat(lCat);
      const result = await this.globalService.updatePlaceCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updatePlaceCategory");
        this.showError.emit(apiErr);
        return;
      }
    }
    this.redrawSavedPlaces();
  }

  public async onDropPlaceCategory(event: CdkDragDrop<string[]>) {
    // console.log("saved-items:onDropPlace-event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    // console.log("saved-items:onDropPlace-savedPlaces", this.savedPlaces);
    if (this.saveType === "local") {
      localStoreSavedPlaces(this.savedPlacesLocaly);
    }
    if (this.saveType === "server") {
      for (const lCat of this.savedPlacesServer) {
        const index = this.savedPlacesServer.indexOf(lCat);
        if (index !== lCat.order) {
          lCat.order = index;
          const sCat = convertLPlaceCatToSPlaceCat(lCat);
          const result = await this.globalService.updatePlaceCategory(sCat);
          if (result.status !== EnumGlobalStatusCode.Success) {
            const apiErr = this.createApiError(result, "updatePlaceCategory");
            this.showError.emit(apiErr);
            return;
          }
        }
      }
    }
  }

  // saved places
  public async onDeletePlaceClick(index: number, catIndex: number) {
    // console.log("saved-items:onDeletePlaceClick-index", index);
    // console.log("saved-items:onDeletePlaceClick-catIndex", catIndex);
    this.selectedSavedPlaceCategoryIndex = catIndex;
    this.selectedPlaceIndex = index;
    // console.log("saved-items:onDeletePlaceClick-savedPlaces", this.savedPlaces);
    if (this.saveType === "local") {
      this.savedPlaces[catIndex].placeItems.splice(index, 1);
      localStoreSavedPlaces(this.savedPlaces);
    }
    if (this.saveType === "server") {
      const placeId = this.savedPlaces[catIndex].placeItems[index].id;
      const result = await this.globalService.deletePlace(placeId);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "deletePlace");
        this.showError.emit(apiErr);
        return;
      }
      this.savedPlaces[catIndex].placeItems.splice(index, 1);
    }
    this.selectedPlaceIndex = undefined;
    this.redrawSavedPlaces();
  }

  public onRenamePlaceClick(index: number, catIndex: number) {
    this.selectedPlaceIndex = index;
    this.selectedSavedPlaceCategoryIndex = catIndex;
    this.isActiveRenameSavedPlace = true;
    setTimeout(() => { this.placeInputElement.nativeElement.focus(); });
  }
  public async onPlaceNameKeyup(event: KeyboardEvent, catIndex: number, index: number) {
    if (event.key === "Enter") {
      if (this.saveType === "local") {
        localStoreSavedPlaces(this.savedPlaces);
      }
      if (this.saveType === "server") {
        const cat = this.savedPlaces[catIndex];
        const lPlace = cat.placeItems[index];
        const sPlace = convertLPlaceToSPlace(lPlace, cat.categoryId);
        const result = await this.globalService.updatePlace(sPlace);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "updatePlace");
          this.showError.emit(apiErr);
          return;
        }
      }
      if (this.savedPlaces[catIndex].visible) { this.redrawSavedPlaces(); }
      this.isActiveRenameSavedPlace = false;
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onPlaceNameBlur(catIndex: number, index: number) {
    this.isActiveRenameSavedPlace = false;
    if (this.saveType === "local") {
      localStoreSavedPlaces(this.savedPlaces);
    }
    if (this.saveType === "server") {
      const cat = this.savedPlaces[catIndex];
      const lPlace = cat.placeItems[index];
      const sPlace = convertLPlaceToSPlace(lPlace, cat.categoryId);
      const result = await this.globalService.updatePlace(sPlace);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updatePlace");
        this.showError.emit(apiErr);
        return;
      }
    }
    if (this.savedPlaces[catIndex].visible) { this.redrawSavedPlaces(); }
    // this.redrawSavedPlaces();
    this.isActiveRenameSavedPlace = false;
  }
  public onZoomToPlaceClick(index: number, catIndex: number) {
    // console.log("saved-items:onZoomToPlaceClick-catIndex", catIndex);
    // console.log("saved-items:onZoomToPlaceClick-index", index);
    const cat = this.savedPlaces[catIndex];
    const lPlace = cat.placeItems[index];
    this.zoomToPlace.emit(lPlace);
    if (!cat.visible) {
      cat.visible = true;
      this.onPlaceCategoryVisiblilityChange(catIndex);
    }
  }

  public async onDropPlace(event: CdkDragDrop<string[]>) {
    // console.log("saved-items:onDropPlace-event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const catIndex = event.container.id;
      this.savedPlaces[catIndex].open = true;
    }
    if (this.saveType === "local") {
      localStoreSavedPlaces(this.savedPlacesLocaly);
    }
    if (this.saveType === "server") {
      const catIndex = event.container.id;
      // console.log("saved-items:onDropRoute-catIndex", catIndex);
      const lCat = this.savedPlacesServer[catIndex];
      const droppedLPlace = lCat.placeItems[event.currentIndex];
      droppedLPlace.categoryId = lCat.id;
      const droppedSPlace = convertLPlaceToSPlace(droppedLPlace, lCat.categoryId);
      const result = await this.globalService.updatePlace(droppedSPlace);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updatePlace");
        this.showError.emit(apiErr);
        return;
      }
      for (const lPlace of lCat.placeItems) {
        const index = lCat.placeItems.indexOf(lPlace);
        if (index !== lPlace.order) {
          lPlace.order = index;
          const sPlace = convertLPlaceToSPlace(lPlace, lCat.categoryId);
          // console.log("saved-items:onDropPlace-sPlace", sPlace);
          const result = await this.globalService.updatePlace(sPlace);
          if (result.status !== EnumGlobalStatusCode.Success) {
            const apiErr = this.createApiError(result, "updatePlace");
            this.showError.emit(apiErr);
            return;
          }
        }
      }
    }
    this.redrawSavedPlaces();
  }

  private redrawSavedPlaces() {
    if (this.saveType === "local") {
      this.redrawSavedPlacesLocaly.emit();
    }
    if (this.saveType === "server") {
      this.redrawSavedPlacesServer.emit();
    }
  }

  // export places
  public onExportPlacesClick() {
    this.downloadPlacesAsJSON();
  }
  private async downloadPlacesAsJSON() {
    // console.log("saved-items:downloadPlacesAsJSON-savePlaces", this.savedPlaces);
    const data = JSON.stringify(this.savedPlaces);
    // console.log("saved-items:downloadPlacesAsJSON-data", data);
    const blob = new Blob([data], { type: "application/octet-stream" });
    this.fileUrlPlaces = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }
  // import places
  public onFileChangePlaces(event: any) {
    // console.log("saved-items:onFileChangePlaces-event", event);
    const files = event.target.files;
    if (files.length === 0) { return; }
    const file = files[0];
    // console.log("saved-items:onFileChangePlaces-file", file);

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      // console.log("FileReader-result", fileReader.result);
      const jsonString = fileReader.result as string;
      const obj = JSON.parse(jsonString);
      // console.log("saved-items:onFileChangePlaces-obj", obj);
      this.savedPlaces = obj;
      localStoreSavedPlaces(this.savedPlaces);
      this.redrawSavedPlacesLocaly.emit();
    };
    fileReader.readAsText(file);
  }



  // saved-tour-categories
  public onOrderTourCategoriesClick() {
    this.orderTourCategories = !this.orderTourCategories;
  }

  // tour-categories
  public async onTourCategoryVisiblilityChange(catIndex: number) {
    // console.log("saved-items:onTourCategoryVisiblilityChange");
    const lCat = this.savedTours[catIndex];
    const sCat = convertLTourCatToSTourCat(lCat);
    sCat.userId = this.loggedInUser.id;
    const result = await this.globalService.updateTourCategory(sCat);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updateTourCategory");
      this.showError.emit(apiErr);
      return;
    }
    this.redrawSavedTours();
  }
  public async onDeleteTourCategoryClick(catIndex: number) {
    const cat = this.savedTours[catIndex];
    const result = await this.globalService.deleteTourCategory(cat.categoryId);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "deleteTourCategory");
      this.showError.emit(apiErr);
      return;
    }
    this.savedTours.splice(catIndex, 1);
    this.redrawSavedTours();
  }
  public onRenameTourCategoryClick(index: number) {
    this.selectedSavedTourCategoryIndex = index;
    this.isActiveRenameSavedTourCategory = true;
    setTimeout(() => { this.catTourInputElement.nativeElement.focus(); });
  }
  public async onTourCategoryNameKeyup(event: KeyboardEvent, catIndex: number) {
    if (event.key === "Enter") {
      const lCat = this.savedTours[catIndex];
      const sCat = convertLTourCatToSTourCat(lCat);
      sCat.userId = this.loggedInUser.id;
      const result = await this.globalService.updateTourCategory(sCat);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateRouteCategory");
        this.showError.emit(apiErr);
        return;
      }
      this.isActiveRenameSavedTourCategory = false;
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }

  public async onTourCategoryNameBlur(catIndex: number) {
    console.log("saved-items:onTourCategoryNameBlur");
    const lCat = this.savedTours[catIndex];
    const sCat = convertLTourCatToSTourCat(lCat);
    sCat.userId = this.loggedInUser.id;
    const result = await this.globalService.updateTourCategory(sCat);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updateTourCategory");
      this.showError.emit(apiErr);
      return;
    }
    this.isActiveRenameSavedTourCategory = false;
  }
  public async onNewTourCategoryNameKeyup(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.newCategoryName || this.newCategoryName === "") { return; }
      await this.onCreateNewTourCategory();
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onCreateNewTourCategory() {
    // console.log("saved-items:onCreateNewTourCategory");
    let lCat: SavedTourCategory;
    if (this.savedTours.length === 0) {
      lCat = createDefaultSavedTourCategoryRemember(this.languageCode);
    } else {
      lCat = createNewTourCategory(this.newCategoryName);
    }
    const sCat = convertLTourCatToSTourCat(lCat);
    console.log("saved-items:onCreateNewTourCategory-sCat", sCat);
    sCat.userId = this.loggedInUser.id;
    const result = await this.globalService.addTourCategory(sCat);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "addTourCategory");
      this.showError.emit(apiErr);
      return;
    }
    const addedCategory = result.addedTourCategory;
    lCat.categoryId = addedCategory.id;
    this.savedTours.push(lCat);
    this.newCategoryName = "";
  }
  public async onTourCategoryColorChange(catIndex: number) {
    // console.log("saved-items:onTourCategoryColorChange-colorValue", this.colorValue);
    // console.log("saved-items:onTourCategoryColorChange-catIndex", catIndex);
    const lCat = this.savedTours[catIndex];
    const sCat = convertLTourCatToSTourCat(lCat);
    sCat.userId = this.loggedInUser.id;
    const result = await this.globalService.updateTourCategory(sCat);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updateTourCategory");
      this.showError.emit(apiErr);
      return;
    }
    // this.redrawSavedTours();
  }

  public async onDropTourCategory(event: CdkDragDrop<string[]>) {
    // console.log("saved-items:onDropTour-event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    // console.log("saved-items:onDropTour-savedTours", this.savedTours);
    for (const lCat of this.savedToursServer) {
      const index = this.savedToursServer.indexOf(lCat);
      if (index !== lCat.order) {
        lCat.order = index;
        const sCat = convertLTourCatToSTourCat(lCat);
        const result = await this.globalService.updateTourCategory(sCat);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "updateTourCategory");
          this.showError.emit(apiErr);
          return;
        }
      }
    }
  }

  public async onDropTour(event: CdkDragDrop<string[]>) {
    console.log("saved-items:onDropTour-event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const catIndex = event.container.id;
      this.savedTours[catIndex].open = true;
    }
    const catIndex = event.container.id;
    // console.log("saved-items:onDropTour-catIndex", catIndex);
    const lCat = this.savedTours[catIndex];
    // console.log("saved-items:onDropTour-lCat", lCat);
    const droppedLTour = lCat.tourItems[event.currentIndex];
    droppedLTour.categoryId = lCat.categoryId;
    // console.log("saved-items:onDropTour-droppedLTour", droppedLTour);
    // const droppedSTour = convertLTourToSTour(droppedLTour, lCat.categoryId);
    const droppedSTour = droppedLTour;
    const result = await this.globalService.updateTour(droppedSTour);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updateTour");
      this.showError.emit(apiErr);
      return;
    }
    for (const lTour of lCat.tourItems) {
      const index = lCat.tourItems.indexOf(lTour);
      if (index !== lTour.order) {
        lTour.order = index;
        // const sTour = convertLTourToSTour(lTour, lCat.categoryId);
        const sTour = lTour;
        const result = await this.globalService.updateTour(sTour);
        if (result.status !== EnumGlobalStatusCode.Success) {
          const apiErr = this.createApiError(result, "updateTour");
          this.showError.emit(apiErr);
          return;
        }
      }
    }
    this.redrawSavedTours();
  }
  public onActivateTourClicked(itemIndex: number, catIndex: number) {
    // console.log("saved-items:onActivateTourClicked-catIndex", catIndex);
    // console.log("saved-items:onActivateTourClicked-itemIndex", itemIndex);
    this.activateSavedTourServer.emit(this.savedTours[catIndex].tourItems[itemIndex]);
  }
  public onRenameTourClick(index: number, catIndex: number) {
    this.selectedTourIndex = index;
    this.selectedSavedTourCategoryIndex = catIndex;
    this.isActiveRenameSavedTour = true;
    setTimeout(() => { this.tourInputElement.nativeElement.focus(); });
  }
  public async onTourNameKeyup(event: KeyboardEvent, catIndex: number, index: number) {
    if (event.key === "Enter") {
      const cat = this.savedTours[catIndex];
      const lTour = cat.tourItems[index];
      // const sTour = convertLTourToSTour(lTour, cat.categoryId);
      const sTour = lTour;
      const result = await this.globalService.updateTour(sTour);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "updateTour");
        this.showError.emit(apiErr);
        return;
      }
      if (this.savedTours[catIndex].visible) {
        this.redrawSavedTours();
      }
      this.isActiveRenameSavedTour = false;
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public async onTourNameBlur(catIndex: number, index: number) {
    this.isActiveRenameSavedTour = false;
    const cat = this.savedTours[catIndex];
    const lTour = cat.tourItems[index];
    // const sTour = convertLTourToSTour(lTour, cat.categoryId);
    const sTour = lTour;
    const result = await this.globalService.updateTour(sTour);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "updateTour");
      this.showError.emit(apiErr);
      return;
    }
    if (this.savedTours[catIndex].visible) { this.redrawSavedTours(); }
    this.isActiveRenameSavedTour = false;
  }
  private redrawSavedTours() {
  }
  public async onDeleteTourClick(index: number, catIndex: number) {
    // console.log("saved-items:onDeleteTourClick-index", index);
    // console.log("saved-items:onDeleteTourClick-catIndex", catIndex);
    this.selectedSavedTourCategoryIndex = catIndex;
    this.selectedTourIndex = index;
    // console.log("saved-items:onDeleteTourClick-savedTours", this.savedTours);
    const tourId = this.savedTours[catIndex].tourItems[index].id;
    const result = await this.globalService.deleteTour(tourId);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "deleteTour");
      this.showError.emit(apiErr);
      return;
    }
    this.savedTours[catIndex].tourItems.splice(index, 1);
    this.selectedTourIndex = undefined;
    this.redrawSavedTours();
  }


  private createApiError(result: any, funcName: string) {
    const apiErr = {} as ApiError;
    apiErr.funcName = funcName;
    apiErr.status = result.status;
    apiErr.errorCode = result.errorCode;
    apiErr.errorMessage = result.errorMessage;
    return apiErr;
  }


}

