import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-info-faq-component",
  templateUrl: "./info-faq.component.html",
  styleUrls: ["./info-faq.component.scss"]
})
export class InfoFaqComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() activate: string;


  public accordionItemB3Expanded = false;
  public accordionItemB4Expanded = false;

  @ViewChild("faqId") faqElement: ElementRef;
  @ViewChild("scrollB3") b3Element: ElementRef;
  @ViewChild("accordionItemB3") accB3Element: ElementRef;

  constructor(
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // this.languageCode = getUserLanguage();
    // this.languageCode = "en"; // until other versions available
    // console.log("Faq:ngOnInit", this.languageCode);
    // title
    let title = "Frequently asked questions";
    if (this.languageCode === "de") { title = "Häufig gestellte Fragen"; }
    title += " | Trip4You-Maps";
    this.titleService.setTitle(title);
    // meta viewport
    let metaDesc = document.querySelector("meta[name='viewport']");
    metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    let description = "Frequently asked questions about Trip4You-Maps - usage, data protection, installation tips, sharing location, embedding in your own websites";
    if (this.languageCode === "de") {
      description = "Häufig gestellte Fragen über Trip4You-Maps - Nutzung, Datenschutz, Tipps zur Installation, Standort freigeben, Einbinden in eigene Webseiten";
    }
    metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);

    // console.log("Faq:ngOnInit-activate", this.activate);
    setTimeout(() => {
      if (this.activate === "faq-loc") {
        this.accordionItemB3Expanded = true;
        // this.accordionItemB4Expanded = true;
      }
    }, 1000);

  }

  public async ngAfterViewInit() {
    // console.log("Faq:ngAfterViewInit", this.languageCode);
    if (!this.activate) { this.faqElement.nativeElement.scrollIntoView({ behavior: "smooth" }); }
    if (this.activate === "faq-loc") {
      this.b3Element.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  public ngOnDestroy(): void {
  }

  public async onRouteQueryParamsChanged(paramMap: ParamMap) {
    console.log("Faq:onRouteParamsChanged-paramMap", paramMap);
  }


}

