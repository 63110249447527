import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { getUserLanguage } from "src/app/utils/utils";

import { environment } from "src/environments/environment";

@Component({
  selector: "app-info-about-component",
  templateUrl: "./info-about.component.html",
  styleUrls: ["./info-about.component.scss"]
})
export class InfoAboutComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;

  public releaseDate: string = environment.releaseDate;
  public releaseVersion = environment.releaseVersion;

  @ViewChild("aboutId") aboutElement: ElementRef;

  constructor(
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // this.languageCode = "en"; // for testing other languages
    // console.log("About:ngOnInit-languageCode", this.languageCode);
    // title
    let title = "About route planner & outdoor app";
    if (this.languageCode === "de") { title = "Über die Routenplaner & Outdoor-App"; }
    title += " | Trip4You-Maps";
    this.titleService.setTitle(title);
    // meta viewport
    let metaDesc = document.querySelector("meta[name='viewport']");
    metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    let description = "Basic information about the functionality of Trip4You-Maps - route planning, navigation, search, location sharing & live tracking";
    if (this.languageCode === "de") {
      description = "Grundlegegende Informationen über die Funktionalität von Trip4You-Maps - Routenplanung, Navigation, Suche, Standort teilen & Live-Tracking";
    }
    metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);
  }
  public ngAfterViewInit(): void {
    this.aboutElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public async ngOnChanges(changes: SimpleChanges) {
    //  console.log("About:ngOnChanges-changes", changes);
  }

  public ngOnDestroy(): void {
  }



}
