import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { getUserLanguage } from "src/app/utils/utils";

@Component({
  selector: "app-info-termsofuse-component",
  templateUrl: "./info-termsofuse.component.html",
  styleUrls: ["./info-termsofuse.component.scss"]
})
export class InfoTermsOfUseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;

  @ViewChild("termsofuseId") termsofuseElement: ElementRef;

  constructor(
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // this.languageCode = "en"; // until other versions available
    // console.log("TermsOfUse:ngOnInit-languageCode", this.languageCode);
    // title
    let title = "Terms of use";
    if (this.languageCode === "de") { title = "Nutzungsbedingungen"; }
    title += " | Trip4You-Maps";
    this.titleService.setTitle(title);
    // meta viewport
    let metaDesc = document.querySelector("meta[name='viewport']");
    metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    let description = "Information about the terms of use of Trip4You-Maps";
    if (this.languageCode === "de") {
      description = "Information über die Nutzungsbedingungen von Trip4You-Maps";
    }
    metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);
  }

  public async ngAfterViewInit() {
    // console.log("TermsOfUse:ngAfterViewInit", this.languageCode);
    this.termsofuseElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy(): void {
  }



}

