<div *ngIf="true" style="padding:0.5rem;">

    <!-- tracking-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onTrackingBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.TRACK.DLG.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onTrackingBackClick()">
    </div>
    <hr style="margin-top:0.2rem; margin-bottom:0.5rem;">

    <!-- movetype -->
    <div style="font-size: medium; display:flex; justify-content:center;">
        <div
            style="margin-top:0.5rem; margin-bottom:0.5rem; padding: 0.5rem; border: 1px solid black; border-radius: 5px;">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='bike' ? 'greenyellow' : ''"
                src="./assets/icons/bike.svg" title="{{'MAPS.RPL.DLG.BYBIKE_T' | translate}}" alt="symbol"
                (click)="onSetRoutePlannerMoveType('bike')">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='ebike' ? 'greenyellow' : ''"
                src="./assets/icons/bicycle-electric.svg" title="{{'MAPS.RPL.DLG.BYEBIKE_T' | translate}}" alt="symbol"
                (click)="onSetRoutePlannerMoveType('ebike')">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='bike-road' ? 'greenyellow' : ''"
                src="./assets/icons/bike-road.svg" title="{{'MAPS.RPL.DLG.BYBIKEROAD_T' | translate}}" alt="symbol"
                (click)="onSetRoutePlannerMoveType('bike-road')">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='mtb' ? 'greenyellow' : ''"
                src="./assets/icons/bike-mtb.svg" title="{{'MAPS.RPL.DLG.BYMTB_T' | translate}}" alt="symbol"
                (click)="onSetRoutePlannerMoveType('mtb')">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='walking' ? 'greenyellow' : ''"
                src="./assets/icons/walking.svg" title="{{'MAPS.RPL.DLG.WALKING_T' | translate}}" alt="symbol"
                (click)="onSetRoutePlannerMoveType('walking')">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='hiking' ? 'greenyellow' : ''"
                src="./assets/icons/hiking.svg" width="24px" title="{{'MAPS.RPL.DLG.HIKING_T' | translate}}"
                alt="symbol" (click)="onSetRoutePlannerMoveType('hiking')">
            <img [class.CursorPointer]="true" style="margin-left:0.5rem;"
                [style.background-color]="this.tourData.moveType==='car' ? 'greenyellow' : ''"
                src="./assets/icons/car-hatchback.svg" title="{{'MAPS.RPL.DLG.BYCAR_T' | translate}}" alt="symbol"
                (click)="onSetRoutePlannerMoveType('car')">
        </div>
    </div>

    <!-- start/stop tour -->
    <div style="font-size: medium; display:flex; justify-content:center;">
        <button *ngIf="!tourData.isTourStarted && hasGeolocation" mat-raised-button color="primary"
            class="route-button-short" (click)="onStartTourClick()">
            <img height="40px" style="margin-right:0.5rem;" src="./assets/icons-new/record-rec.svg">
            {{'MAPS.TRACK.DLG.START-TOUR' | translate}}
        </button>
        <button *ngIf="tourData.isTourStarted" mat-raised-button color="primary" class="route-button-short"
            (click)="onStopTourClick()">
            <img height="28px" style="margin-right:0.5rem;" src="./assets/icons-new/stop-circle-red.svg">
            {{'MAPS.TRACK.DLG.STOP-TOUR' | translate}}
        </button>
    </div>

    <!-- no geolocation available -->
    <div *ngIf="!hasGeolocation" style="font-size: medium; display:flex; justify-content:center;">
        <span style="font-size:large;">{{'MAPS.TRACK.DLG.GEO-LOC-NOT-AVAIL_MSG' | translate}}</span><br>
    </div>

    <!-- tour-data -->
    <div *ngIf="tourData.tourStartTime"
        style="margin-top:0.5rem; background-color:cornsilk; border: 2px solid grey; border-radius: 20px; padding:0.5rem;">
        <div style="font-size: medium; display:flex; justify-content:center;">
            <div style="font-size: medium; margin-left:1.0rem;">
                <span class="large-data-item">{{'MAPS.TRACK.DLG.TOUR-AT_LBL' | translate}} </span>
                <span class="large-data-item">{{tourData.tourStartTime | date: 'd.M.YYYY'}}</span><br>
                <span class="large-data-item">{{'MAPS.TRACK.DLG.TOUR-STARTED_LBL' | translate}} </span>
                <span class="large-data-item">{{tourData.tourStartTime | date: 'HH:mm'}}</span>
                <span *ngIf="tourData.tourEndTime" class="large-data-item"> {{'MAPS.TRACK.DLG.TOUR-ENDED_LBL' |
                    translate}} </span>
                <span *ngIf="tourData.tourEndTime" class="large-data-item">{{tourData.tourEndTime | date:
                    'HH:mm'}}</span>
            </div>
        </div>
        <!-- <div style="font-size: medium; display:flex; justify-content:center;">
            <div style="font-size: medium; margin-left:0.5rem;">
            </div>
        </div> -->
        <div style="font-size: medium; display:flex; justify-content:center;">
            <div>
                <div class="data-item">
                    <span><img style="margin-right:0.5rem;" width=16px src="./assets/icons/clock-outline.svg"></span>
                    <span class="large-data-text">{{tourData.doneDurationHours}}:</span>
                    <span class="large-data-text">{{tourData.doneDurationMinutes | number: '2.0'}}</span>
                    <br><span>{{'MAPS.TRACK.DLG.DURATION_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span class="large-data-text">&#8596; {{tourData.trackLength/1000 | number: '1.3-3'}}</span>
                    <span> km</span>
                    <br><span>{{'MAPS.TRACK.DLG.DONE-DIST_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span *ngIf="tourData.minAltidute<10000" class="large-data-text">&#9662;
                        {{tourData.minAltidute |
                        number: '1.0-0'}}</span>
                    <span *ngIf="tourData.minAltidute>10000" class="large-data-text">&#9662; --</span>
                    <span> m</span>
                    <br><span>{{'MAPS.TRACK.DLG.MIN-ALT_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span class="large-data-text">&#10138; {{tourData.doneAscents | number: '1.0-0'}}</span>
                    <span> m</span>
                    <br><span>{{'MAPS.TRACK.DLG.ASCENTS_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span *ngIf="tourData.currentAltidute" class="large-data-text">&#9652; {{tourData.currentAltidute |
                        number: '1.0-0'}}</span>
                    <span *ngIf="tourData.currentAltidute==0" class="large-data-text">&#9652; --</span>
                    <span> m</span>
                    <br><span>{{'MAPS.TRACK.DLG.CURRENT-ALT_LBL' | translate}}</span>
                </div>
            </div>
            <div>
                <div class="data-item">
                    <span><img style="margin-right:0.5rem;" width=16px src="./assets/icons/clock-outline.svg"></span>
                    <span class="large-data-text">{{tourData.timeInMotionHours}}:</span>
                    <span class="large-data-text">{{tourData.timeInMotionMinutes | number: '2.0'}}</span>
                    <br><span>{{'MAPS.TRACK.DLG.IN-MOTION_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span><img style="margin-right:0.5rem;" width=16px
                            src="./assets/icons-new/diameter-variant.svg"></span>
                    <span *ngIf="tourData.averageVelocity" class="large-data-text">{{tourData.averageVelocity | number:
                        '1.1-1'}}</span>
                    <span *ngIf="!tourData.averageVelocity" class="large-data-text">0.0</span>
                    <span> km/h</span>
                    <br><span>{{'MAPS.TRACK.DLG.AVE-VELOCITY_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span *ngIf="tourData.maxAltidute>0" class="large-data-text">&#9652; {{tourData.maxAltidute |
                        number: '1.0-0'}}</span>
                    <span *ngIf="tourData.maxAltidute==0" class="large-data-text">&#9652; --</span>
                    <span> m</span>
                    <br><span>{{'MAPS.TRACK.DLG.MAX-ALT_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span class="large-data-text">&#10136; {{tourData.doneDescents | number: '1.0-0'}}</span>
                    <span> m</span>
                    <br><span>{{'MAPS.TRACK.DLG.DESCENTS_LBL' | translate}}</span>
                </div>
                <div class="data-item">
                    <span class="large-data-text">&#8611; {{tourData.currentVelocity | number: '1.1-1'}}</span>
                    <span> km/h</span>
                    <br><span>{{'MAPS.TRACK.DLG.CURRENT-VELOCITY_LBL' | translate}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- numGaps -->
    <div *ngIf="tourData.numGaps>0" style="font-size: medium; display:flex; justify-content:center;">
        <div style="margin:1.0rem;font-size:large">
            <span>{{'MAPS.TRACK.DLG.TOUR-GAPS_MSG1' | translate}} </span>
            <span>{{tourData.numGaps}} </span>
            <span>{{'MAPS.TRACK.DLG.TOUR-GAPS_MSG2' | translate}}</span><br>
            <span style="font-size:smaller">{{'MAPS.TRACK.DLG.TOUR-GAPS_MSG3' | translate}}</span><br>
        </div>
    </div>
    <hr>


    <!-- show track on map -->
    <div style="font-size: medium; display:flex; justify-content:center;">

        <div style="padding:0.5rem;">
            <mat-slide-toggle color="primary" style="margin-left:2.0rem;" [(ngModel)]="tourData.showTrack"
                (change)="onShowTrackChanged()">{{'MAPS.TRACK.DLG.SHOW-TRACK-ON-MAP_TEXT' | translate}}
            </mat-slide-toggle>
        </div>
    </div>

    <!-- tracking functions -->
    <div style="font-size: medium; display:flex; justify-content:center;">
        <div>
            <div *ngIf="tourData.trackPoints && tourData.trackPoints.length > 1" style="padding:0.5rem;">
                <!-- zoom to track & show details-->
                <button mat-raised-button color="primary" style="width:8.0rem;"
                    (click)="onZoomToTrackClick()">{{'MAPS.TRACK.DLG.ZOOM-TO-TRACK_BUTT_TEXT' | translate}}</button>
                <!-- show-elevation-profile -->
                <button mat-raised-button color="primary" style="width:8.0rem;margin-left:0.5rem;"
                    (click)="onShowElevationProfileClick()">
                    {{'MAPS.TRACK.DLG.SHOW-ELE-PROFILE_BUTT_TEXT' | translate}}</button>
            </div>
        </div>
    </div>

    <!-- show elevation profile chart -->
    <div *ngIf="showElevationProfile && hasElevationData">
        <div>
            <app-elevation-profile-chart-component [data]="elevationChartData" [languageCode]="languageCode">
            </app-elevation-profile-chart-component>
        </div>
    </div>
    <hr>

    <!-- tour-name -->
    <div style="margin-top:1.0rem; font-size: medium; display:flex; justify-content:center;">
        <mat-form-field>
            <input matInput class="name-input" style="font-size:large;" type="text" spellcheck="false"
                [(ngModel)]="tourData.name" placeholder="{{'MAPS.TRACK.DLG.NAME_INPUT_PLH' | translate}}">
        </mat-form-field>
    </div>

    <!-- save-tour& download track-->
    <div *ngIf="tourData.name && tourData.trackPoints.length> 1"
        style="margin-top:0.5rem; font-size: medium; display:flex; justify-content:center;">
        <button mat-raised-button [disabled]="!loggedInUser" color="primary" (click)="onSaveTourClick()">
            <img src="./assets/icons/download-outline.svg" alt="Save tour">{{'MAPS.TRACK.DLG.SAVE_MSG' | translate}}
        </button>
        <button mat-raised-button color="primary" style="margin-left:0.5rem;" (click)="onSaveTrackAsGpxClick()">
            <img src="./assets/icons/download-outline.svg" alt="Download as GPX">
            {{'MAPS.TRACK.DLG.DOWNLOAD_BUTT_TEXT' | translate}}
        </button>
    </div>


</div>