import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { GlobalService } from "src/app/services/global.service";
import { getUserLanguage } from "src/app/utils/utils";
import { EnumActionType, EnumGlobalStatusCode, UsageLogMaps } from "src/dto.generated/api";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer-bugs-component",
  templateUrl: "./footer-bugs.component.html",
  styleUrls: ["./footer-bugs.component.scss"]
})
export class FooterBugsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;

  public isOnline: boolean;
  public appVersion: string;

  public selectType: string;
  public bugText: string;
  public sentBug: boolean;
  public questionText: string;
  public sentQuestion: boolean;
  public clientEmail: string;
  public missingClientEmail: boolean;
  public wrongClientEmail: boolean;

  @ViewChild("bugsId") bugsElement: ElementRef;

  constructor(
    private titleService: Title,
    private globalService: GlobalService,
  ) { }

  public async ngOnInit() {
    // this.languageCode = getUserLanguage();
    // this.languageCode = "en"; // until other versions available
    // console.log("FooterBugs:ngOnInit", this.languageCode);

    // title
    let title = "Feedback";
    title += " | Trip4You-Maps";
    this.titleService.setTitle(title);
    // meta viewport
    let metaDesc = document.querySelector("meta[name='viewport']");
    metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    let description = "Give us feedback about bugs and problems or send a question";
    if (this.languageCode === "de") {
      description = "Melde uns Fehler und Probleme oder sende eine Anfrage";
    }
    metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);

    // this.isOnline = true;
    this.selectType = "bug";

  }

  public async ngAfterViewInit() {
    // console.log("FooterBugs:ngAfterViewInit", this.languageCode);
    this.bugsElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy(): void {
  }

  public onSelectFeedbackClick(type: string) {
    // console.log("FooterBugs:onSelectFeedbackClick", type);
    this.sentBug = false;
    this.sentQuestion = false;
    this.missingClientEmail = false;
    this.selectType = type;
  }
  public onBugTextChange(event: Event) {
  }
  public onSendBugClick() {
    // console.log("FooterBugs:onSendBugClick", this.bugText);
    if (!this.bugText || this.bugText.length === 0) { return; }
    this.wrongClientEmail = false;
    if (this.clientEmail && this.clientEmail.length > 0 && !this.checkIfStringIsEmail(this.clientEmail)) {
      this.wrongClientEmail = true;
      return;
    }
    this.sentBug = true;
    const info = "email:" + this.clientEmail + " &info:" + this.bugText;
    this.createUsageLog(EnumActionType.feedback_bug, info);
  }

  public onQuestionTextChange(event: Event) {
  }
  public onSendQuestionClick() {
    // console.log("FooterBugs:onSendQuestionClick-questionText", this.questionText);
    // console.log("FooterBugs:onSendQuestionClick-clientEmail", this.clientEmail);
    if (!this.questionText || this.questionText.length === 0) { return; }
    this.wrongClientEmail = false;
    if (!this.clientEmail || this.clientEmail.length === 0) {
      this.missingClientEmail = true;
      return;
    }
    if (!this.checkIfStringIsEmail(this.clientEmail)) {
      this.wrongClientEmail = true;
      return;
    }
    this.missingClientEmail = false;
    this.sentQuestion = true;
    const info = "email:" + this.clientEmail + " &info:" + this.questionText;
    this.createUsageLog(EnumActionType.feedback_question, info);
  }

  private async createUsageLog(actionType: EnumActionType, info: string) {
    // update isOnline from api-service
    await this.checkIfNetworkAccessOk();
    if (!this.isOnline) { return; }
    // appVersion
    this.appVersion = environment.releaseVersion;

    const startedAt = new Date(Date.now());
    const usageLog = {} as UsageLogMaps;
    usageLog.actionType = actionType;
    usageLog.actionInfo = info;
    usageLog.userLanguage = this.languageCode;
    usageLog.appVer = this.appVersion;
    usageLog.at = startedAt;
    // console.log("FooterBugs:createUsageLog-usageLog", usageLog);
    const results = await this.globalService.addUsageLogMaps(usageLog);
    if (results.status != EnumGlobalStatusCode.Success) {
      if (results.status === EnumGlobalStatusCode.HttpError) { this.isOnline = false; }
      // const apiErr = createApiError(results, "addUsageLogMaps");
      // this.showApiError(apiErr);
      return;
    }
  }

  private async checkIfNetworkAccessOk() {
    // §todo check for online
    const result = await this.globalService.isApiAvailable();
    if (result.status === EnumGlobalStatusCode.Success) {
      this.isOnline = true;
    } else {
      this.isOnline = false;
    }
    // console.log("Maps:checkIfNetworkAccessOk-isOnline", this.isOnline);
  }

  private checkIfStringIsEmail(emailValue: string) {
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail = emailPattern.test(emailValue);
    console.log("FooterBugs:checkIfStringIsEmail-isEmail", isEmail);
    return isEmail;
  }

}
